import { PayloadAction, createSlice, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { RoomStates } from '../../base/types';
import {
    RoomState as RoomStateClient,
    QualityIndicatorState,
    ContentStreamMetadata,
    QualityIndicatorType,
} from '../../room-client/common';
import {
    ContentState,
    Participant,
    VoteState,
    VoteStreakState,
} from '../../room-client/common/typings';
import { Room, ContentStream, User, RoomType, RoomSettings } from '../../api-client/types';
import {
    setRoom,
    exitRoom,
    joinRoom,
    leaveRoom,
    updateRoomName,
    updateStage,
} from './sharedActions';
import { NormalizedState } from '../types';
import { normalize } from '../utils';
import { init as initUser } from '../user';

export interface RoomState extends Omit<RoomStateClient, 'participants' | 'userParticipantIDs'> {
    instance: Room;
    previous: Room;
    participant: Participant;
    roomType: RoomType;
    dataLoaded: boolean;
    activeSpeakerID: string;
    connected: boolean;
    connecting: boolean;
    reconnecting: boolean;
    creating: boolean;
    creatorID: string;
    downVotes: VoteState['downVotes'];
    guestName?: string;
    id: string;
    joined: boolean;
    lastContentStream: ContentStream;
    losers: VoteStreakState['losers'];
    name: string;
    participantID: string;
    state: RoomStates;
    upVotes: VoteState['upVotes'];
    urlName: string;
    winners: VoteStreakState['winners'];
    participants: NormalizedState<Participant>;
    userParticipants: { [userID: string]: Participant['id'] };
    members: NormalizedState<User>;
    activeMembers: NormalizedState<User>;
    tvAuthPreview: number;
}

export const initialState: RoomState = {
    instance: null,
    previous: null,
    settings: <RoomSettings>{},
    participant: null,
    roomType: null,
    dataLoaded: false,
    activeSpeakerID: '',
    connected: false,
    connecting: true,
    reconnecting: false,
    contentState: <ContentState>{},
    contentStreamMetadataState: <ContentStreamMetadata>{},
    creating: false,
    creatorID: '',
    downVotes: {},
    id: '',
    joined: false,
    lastContentStream: <ContentStream>{},
    losers: {},
    name: '',
    participantID: '',
    participants: {
        byId: {},
        allIds: [],
    },
    userParticipants: {},
    members: {
        byId: {},
        allIds: [],
    },
    activeMembers: {
        byId: {},
        allIds: [],
    },
    qualityIndicatorState: <QualityIndicatorState>{},
    state: RoomStates.Loading,
    upVotes: {},
    urlName: null,
    winners: {},
    voteState: <RoomStateClient['voteState']>{},
    voteStreakState: <RoomStateClient['voteStreakState']>{},
    stage: <RoomStateClient['stage']>{
        members: {},
        pendingInvites: {},
        pendingRequests: {},
    },
    muted: <RoomStateClient['muted']>{},
    maxSubs: null,
    subsQueueSize: null,
    tvAuthPreview: -1,
};

const getRoomState = (currentState: RoomStates, newContentState: ContentState) => {
    switch (newContentState) {
        case ContentState.Playing:
        case ContentState.Paused:
            return null;
        case ContentState.Stopped:
            return RoomStates.VideoPending;
        default:
            return currentState;
    }
};

export const extraReducers = (
    builder: ActionReducerMapBuilder<RoomState>
): ActionReducerMapBuilder<RoomState> => {
    builder
        .addCase(initUser, (state, action) => {
            if (!state.joined) {
                return;
            }

            if (!state.members.byId[action.payload.id]) {
                state.members.allIds.push(action.payload.id);
            }

            state.members.byId[action.payload.id] = action.payload;
        })
        .addCase(updateRoomName, (state, action) => {
            const { roomID, name } = action.payload;
            if (roomID === state.id) {
                state.name = name;
                state.instance.name = name;
            }
        })
        .addCase(setRoom, (state, action) => {
            const { id, settings, userID, urlName, name, members, activeMembers, roomType } =
                action.payload;
            state.previous = state.instance;
            state.instance = action.payload;
            state.roomType = roomType;
            state.settings = settings;
            state.creating = false;
            state.id = id;
            state.creatorID = userID;
            state.urlName = urlName;
            state.name = name;
            state.members = normalize<User>(members);
            state.activeMembers = normalize<User>(activeMembers);
        })
        .addCase(updateStage, (state, action) => {
            state.stage = action.payload;
        })
        .addCase(joinRoom, (state) => {
            state.joined = true;
        })
        .addCase(leaveRoom, (state) => {
            state.joined = false;
            state.guestName = null;
        })
        .addCase(exitRoom, (state) => {
            return {
                ...initialState,
                previous: state.instance,
            };
        });

    return builder;
};

export const roomSlice = createSlice({
    name: 'room',
    initialState,
    reducers: {
        createRoom(state) {
            state.creating = true;
        },
        cancelCreateRoom(state) {
            state.creating = false;
        },
        setID(state, action: PayloadAction<string>) {
            state.id = action.payload;
        },
        setParticipantID(state, action: PayloadAction<Participant['id']>) {
            state.participantID = action.payload;
        },
        setConnecting(state) {
            state.connecting = true;
        },
        setReconnecting(state) {
            state.reconnecting = true;
        },
        setConnected(state, action: PayloadAction<boolean>) {
            state.connected = action.payload;
            state.connecting = false;
            state.reconnecting = false;
        },
        setState(state, action: PayloadAction<RoomStates>) {
            state.state = action.payload;
        },
        setRoomState(state, action: PayloadAction<RoomStateClient>) {
            return {
                ...state,
                ...action.payload,
                participant: action.payload.participants[state.participantID],
                dataLoaded: true,
                participants: {
                    // Sort by connect time asc
                    allIds: Object.keys(action.payload.participants).sort(
                        (p1, p2) =>
                            action.payload.participants[p1].connectedAt -
                            action.payload.participants[p2].connectedAt
                    ),
                    byId: action.payload.participants,
                },
                userParticipants: action.payload.userParticipantIDs,
                state: getRoomState(state.state, action.payload.contentState),
                tvAuthPreview: action.payload.participants[state.participantID]?.tvAuthPreview,
            };
        },
        setSubsQueueSize(state, action: PayloadAction<number>) {
            state.subsQueueSize = action.payload;
        },
        setActiveSpeakerID(state, action: PayloadAction<string>) {
            state.activeSpeakerID = action.payload;
        },
        updateSettings(state, action: PayloadAction<RoomSettings>) {
            state.settings = action.payload;
        },
        updateParticipant(state, action: PayloadAction<Participant>) {
            updateParticipantHelper(state, action.payload);
        },
        updateParticipants(state, action: PayloadAction<Participant[]>) {
            action.payload.forEach((participant) => updateParticipantHelper(state, participant));
        },
        removeParticipant(state, action: PayloadAction<Participant['id']>) {
            const current = state.participants.byId[action.payload];
            state.participants.allIds = state.participants.allIds.filter(
                (id) => id !== action.payload
            );

            // Remove member references if this was the only active participant
            // for the corresponding user
            if (
                current &&
                !current.dupeUser &&
                !current.ghost &&
                state.userParticipants[current.userID] === action.payload
            ) {
                delete state.userParticipants[current.userID];
                delete state.activeMembers.byId[current.userID];
                state.activeMembers.allIds = state.activeMembers.allIds.filter(
                    (id) => id !== current.userID
                );
            }

            delete state.participants.byId[action.payload];
        },
        updateContentState(state, action: PayloadAction<ContentState>) {
            state.contentState = action.payload;
            state.state = getRoomState(state.state, action.payload);
        },
        updateContentStreamMetadata(state, action: PayloadAction<ContentStreamMetadata>) {
            state.contentStreamMetadataState = action.payload;

            const metadata = action.payload;
            if (!metadata.id) {
                state.lastContentStream.stoppedOn =
                    state.lastContentStream.stoppedOn || new Date().toISOString();

                return;
            }

            state.lastContentStream = {
                id: metadata.id,
                contentName: metadata.name,
                contentPlatform: metadata.platform,
                contentURL: metadata.url,
                thumbnailURL: metadata.thumbnailURL,
                width: metadata.width,
                height: metadata.height,
                streamedOn: '',
                stoppedOn: '',
            };
        },
        updateVoteStreakState(state, action: PayloadAction<VoteStreakState>) {
            const { winners, losers } = action.payload;
            state.winners = winners;
            state.losers = losers;
        },
        updateVoteState(state, action: PayloadAction<VoteState>) {
            const { upVotes, downVotes } = action.payload;
            state.upVotes = upVotes;
            state.downVotes = downVotes;
        },
        setLastContentStream(state, action: PayloadAction<ContentStream>) {
            state.lastContentStream = action.payload;
        },
        updateMuted(state, action: PayloadAction<RoomStateClient['muted']>) {
            state.muted = action.payload;
        },
        updateQualityIndicator(
            state,
            action: PayloadAction<{ indicatorType: QualityIndicatorType; active: boolean }>
        ) {
            const { indicatorType, active } = action.payload;
            state.qualityIndicatorState[indicatorType] = active;
        },
        updateUser(state, action: PayloadAction<User>) {
            state.members.byId[action.payload.id] = action.payload;
        },
        setWsURL(state, action: PayloadAction<string>) {
            if (state.instance) {
                state.instance.roomServerWSURL = action.payload;
            }
        },
        setTvAuthPreview(state, action: PayloadAction<number>) {
            state.tvAuthPreview = action.payload;
        },
    },
    extraReducers,
});

const updateParticipantHelper = (state: RoomState, participant: Participant) => {
    const { id, userID, name, avatarURL, dupeUser, ghost } = participant;

    if (dupeUser || ghost) {
        return;
    }

    // Update local participant
    if (id === state.participantID) {
        state.participant = participant;
        state.tvAuthPreview = participant.tvAuthPreview;
    }

    // Add to room participants
    if (!state.participants.byId[id]) {
        state.participants.allIds.push(id);
    }

    state.participants.byId[id] = participant;

    // Add user-participant mapping
    if (userID) {
        state.userParticipants[userID] = id;
    }

    // Add/update permanent room member & active member entries
    if (userID) {
        const current = state.members.byId[userID];
        const user = {
            ...(current || {}),
            id: userID,
            name: name,
            avatar: {
                url: avatarURL,
            },
        } as User;

        state.members.byId[userID] = user;
        if (!current) {
            state.members.allIds.push(userID);
        }

        const currentActive = state.activeMembers.byId[userID];
        state.activeMembers.byId[userID] = user;
        if (!currentActive) {
            state.activeMembers.allIds.push(userID);
        }
    }
};

export const {
    createRoom,
    cancelCreateRoom,
    setReconnecting,
    setID,
    setParticipantID,
    setActiveSpeakerID,
    setConnected,
    setRoomState,
    setSubsQueueSize,
    setState,
    updateSettings,
    updateParticipant,
    updateParticipants,
    removeParticipant,
    updateContentState,
    updateContentStreamMetadata,
    updateVoteStreakState,
    updateVoteState,
    setLastContentStream,
    updateMuted,
    updateQualityIndicator,
    updateUser,
    setWsURL,
    setTvAuthPreview,
} = roomSlice.actions;

export default roomSlice.reducer;
